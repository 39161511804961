/* eslint-disable */
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DownloadButtons from "../../components/downloadButtons";
// import phones from '../../images/iphones.png'
import "./style.scss";
import { getUser, useLogout } from "../../utils/auth";
import { isUserSubscribed } from "../../utils/revenueCat";
import { getSubscription, cancelSubscription } from "../../utils/stripe";
import { navigate } from "gatsby-link";
import { head as _head, filter as _filter, get as _get, isEmpty as _isEmpty } from "lodash";
import BRAND_CONFIG from '../../BRAND_CONFIG';
import HeaderLogo from "../../components/header/HeaderLogo";
import SubscriptionStateMessages from "../../components/SubscriptionStateMessages/SubscriptionStateMessages";
import Loading from "../../components/loading";

const ManageSubscriptionPage = () => {
  // TODO: Refactor to use context
  const { name, logo } = BRAND_CONFIG;
  const [state, setState] = React.useState({
    isFetchingSubscriptions: true,
    activeSubscription: null,
    subscriptions: {},
    stripeSubscription: null,
    confirmCancelStripe: false,
    stripeSubscriptionCancelled: false,
    stripeSubscriptionCancelFailed: false,
  });

  React.useEffect(() => {
    hasSubscriptions();
  }, []);

  const logout = useLogout();

  const hasSubscriptions = async () => {
    let user = null;
    try {
      user = await getUser();
    } catch (e) {
      console.log(e);
      console.log("go back to join");
      navigate(`/register`);
    }
    try {
      const subscriptions = await isUserSubscribed(user.username);
      if (!subscriptions) {
        navigate(`/subscribe`);
      }
      const subscription = _head(_filter(subscriptions, (subscription) => subscription.is_active));

      if (!subscription) {
        navigate(`/subscribe`);
      }

      let stripeSubscription = null;

      if (subscription.store === "web") {
        const getSubscriptionResponse = await getSubscription();
        stripeSubscription = _get(getSubscriptionResponse, "data", null);
        stripeSubscription = _isEmpty(stripeSubscription) ? null : stripeSubscription;
      }

      setState({
        ...state,
        isFetchingSubscriptions: false,
        activeSubscription: subscription,
        subscriptions: subscriptions,
        stripeSubscription: stripeSubscription,
        profile: user.attributes,
      });
    } catch (e) {
      console.log("Could not fetch subscriptions");
      console.log(e);
    }
  };

  const onConfirmCancelStripe = () => {
    setState({
      ...state,
      confirmCancelStripe: true,
    });
  };

  const onDisableOnConfirmCancelStripe = () => {
    setState({
      ...state,
      confirmCancelStripe: false,
    });
  };

  const onLogout = async () => {
    logout();
    setState({ ...state, isAuthenticated: false });
    window.location.href = "/";
  };

  const onCancelSubscription = async () => {
    const subscriptionId = state.stripeSubscription.id;
    try {
      const cancelSubscriptionResponse = await cancelSubscription(subscriptionId);

      if (cancelSubscriptionResponse.status === 200) {
        setState({
          ...state,
          stripeSubscriptionCancelled: true,
        });
      } else {
        setState({
          ...state,
          stripeSubscriptionCancelFailed: true,
        });
      }
    } catch (e) {
      setState({
        ...state,
        stripeSubscriptionCancelFailed: true,
      });
    }
  };

  return (
    <Layout>
      <SEO title="Manage Subscription" />
      <div className="page-wrapper">
            {state.isFetchingSubscriptions && <Loading />}
            {!state.isFetchingSubscriptions && (
              <>
                <Col />
                <Col className="text-center mt-3 flex-sm-fill flex--grow-1" style={{width: '500px'}}>
                  <HeaderLogo title={`You have an active ${state?.activeSubscription?.store} subscription for ${name}`} logo={logo} logoSize={100} />
              
                  <p >Your user is {_get(state.profile, "email", "")}</p>
                     <DownloadButtons />
                 
                    <p>Started on {new Date(state?.activeSubscription?.purchase_date).toLocaleString()}</p>
                    <p>Current period finishes on {new Date(state?.activeSubscription?.expires_date).toLocaleString()}</p>
                    
                    <SubscriptionStateMessages 
                          state={state} 
                          onCancelSubscription={onCancelSubscription}
                          onConfirmCancelStripe={onConfirmCancelStripe}
                          onDisableOnConfirmCancelStripe={onDisableOnConfirmCancelStripe}
                      />
                    
                  
                </Col>
                  <Row className="info-row mt-5 d-flex align-items-sm-end">
                    <Col>
                      <p className="text-center body-font">
                        <a onClick={onLogout}>               
                            Log Out
                        </a>
                      </p>
                    </Col>
                </Row>
                </>
            )}
      </div >
    </Layout>
  );
};

export default ManageSubscriptionPage;
