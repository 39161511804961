import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import BRAND_CONFIG from '../../BRAND_CONFIG';

const SubscriptionStateMessages = ({
    state, onCancelSubscription, onConfirmCancelStripe, onDisableOnConfirmCancelStripe,
}) => (
    <Row className="d-flex justify-content-center align-items-center">
        <Col className=" d-flex flex-column  justify-content-center ">
            {state?.activeSubscription?.unsubscribe_detected_at && (
                <p>You have cancelled the subscription. It will end at the end of this period.</p>
            )}
            {state?.activeSubscription?.store === 'web'
                      && !state?.activeSubscription?.unsubscribe_detected_at
                      && !state?.confirmCancelStripe
                      && state?.stripeSubscription && (

                <Button type="button" className="btn btn-warning" onClick={onConfirmCancelStripe}>
                          Cancel subscription
                         </Button>

            )}
            {state?.activeSubscription?.store === 'web'
                      && !state?.activeSubscription?.unsubscribe_detected_at
                      && !state?.confirmCancelStripe
                      && !state?.stripeSubscription && (
                <p>
                          If you subscribed via website contact with our
                    {' '}
                          <a href={BRAND_CONFIG.links.support}>support center</a>
                          .
                      </p>
            )}
            {state?.confirmCancelStripe
                      && !state?.stripeSubscriptionCancelled
                      && !state?.stripeSubscriptionCancelFailed && (
                      <>
                    <p> Are you sure you want to cancel the Subscription? </p>

                    <div className="justify-content-center">

                              <Button className="btn btn-warning" onClick={onCancelSubscription} style={{ width: '120px' }}>
                            Yes, please.
                        </Button>
                              {' '}
                              <Button className="btn btn-secondary" onClick={onDisableOnConfirmCancelStripe} style={{ width: '120px' }}>
                            No!
                        </Button>

                          </div>
                    <p className="mt-4">
                              It can take up to 2 hours to be cancelled.
                    </p>
                </>
            )}
            {state?.stripeSubscriptionCancelled && (
                <p>Your subscription has been cancelled. It can take up to 2 hours to be shown here.</p>
            )}
            {state?.stripeSubscriptionCancelFailed && (
                <p className="danger-text">There has been an error cancelling the subscription. Please, contact our support center</p>
            )}
            {state?.activeSubscription?.store !== 'web' && (
                <p>
                    If you subscribed via the App Store or Play Store and you wish to cancel your current subscription
                    you will need to do so from within the respective store.
                </p>
            )}
        </Col>
    </Row>
);

export default SubscriptionStateMessages;
